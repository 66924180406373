body {
	font-family: 'Roboto', sans-serif;
	/*font-size: 24px;*/
	font-size: 1.8vw;
	font-weight: 300;
	overflow-x: hidden;
}
header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px 27vw 10px 7vw;
	background: rgb(255, 255, 255);
	z-index: 1;
}
.founded {
	position: fixed;
	top: 40vw;
	right: 40vw;
	font-size: 1.6vw;
	text-shadow: 2px 2px 12px white;
	z-index: 4;
}
.founded hr {
	display: inline-block;
	width: 3em;
	margin-right: 1em;
	margin-left: -4em;
}
.founded span {
	text-transform: uppercase;
	font-weight: 900;
}
#logo {
	width: 17vw;
}
.button-link {
	display: inline-block;
	margin: .3em auto;
	border: 1px solid silver;
	border-radius: 5px;
	padding-right: .3em;
	background: white;
	transition: all .2s ease-in;
}
.button-link:hover {
	box-shadow: 5px 5px 15px -4px rgba(0, 0, 0, 0.245);
}
.button-link a {
	display: inline-block;
	padding: .7em 1.8em .7em .6em;
	font-size: 1.23vw;
	/*font-size: 1rem;*/
	font-weight: 500;
	color: black;
	text-decoration: none;
	background-image: url("../img/plus.svg");
	background-repeat: no-repeat;
	background-position: right;
	background-size: 1.2em;
}
.contact-link {
	position: fixed;
	right: 5vw;
	top: 25vw;
	z-index: 4;
}
footer {
	position: fixed;
	bottom: 0;
	font-size: 1.2vw;
	text-align: center;
	width: 100vw;
	z-index: 5;
	/*background: white;*/
	/*box-shadow: 0 0 17px 7px rgb(255, 255, 255);*/
}
footer p {
	display: inline-block;
	background: #fff;
	border-radius: 1em;
	padding: .1em .5em;
}
section {
	width: 100vw;
	height: 100vh;
	background-position: right top;
	background-size: contain;
	background-repeat: no-repeat;
}
p {
	font-weight: 300;
}
p.welcome {
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.3vw;
	margin-bottom: 0;
}
h1 {
	margin-top: 0;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 3.6vw;
}
.hero {
	position: fixed;
	right: 0;
	width: 50vw;
	height: 53vw;
	background-position: right top;
	background-size: 100% auto;
	background-repeat: no-repeat;
	z-index: 2;
}

section#home .hero {
	background-image: url("../img/hero_home.jpg");
}
section#real-estate .hero {
	background-image: url("../img/hero_real_estate.jpg");
}
section#business-management .hero {
	background-image: url("../img/hero_business_management.jpg");
}
section#wealth-management .hero {
	background-image: url("../img/hero_wealth_management.jpg");
}
section#venture-capital .hero {
	background-image: url("../img/hero_venture_capital.jpg");
}
section#baseball .hero {
	background-image: url("../img/hero_baseball.jpg");
}
section#contact .hero {
	background-image: url("../img/hero_contact.jpg");
}
.grid-container {
	position: absolute;
	top: 200px;
	left: 7vw;
	width: 30vw;
	padding-bottom: 5em;
}
form {
	max-width: 17em;
}
form input,
form textarea,
form button {
	width: 100%;
	border: 1px solid silver;
	margin: .2em 0;
	font-size: 70%;
	font-family: 'Roboto', sans-serif;
	border-radius: 5px;
	padding: .7em 1em;
	box-sizing: border-box;
	box-shadow: none;
}
form input:focus,
form textarea:focus {
	outline: 0;
	border-color: rgba(0, 84, 166, .67);
}
form button {
	color: white;
	background-color: #0054a6;
	text-transform: uppercase;
	font-size: 60%;
	padding: 1em;
	box-shadow: 0 0 5px rgba(0, 84, 166, 0.51);
	cursor: pointer;
	transition: all .3s ease-in;
}
form button:hover {
	background-color: #005EB6;
}
form button:active {
	background-color: #0077E3;
}
form button:disabled {
	background-color: #748185;
}

.alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: 80%;
}
.alert-success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}
.alert-danger {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}

.logos {
	display: block;
	/*justify-content: space-between;*/
	margin-bottom: .5em;
}
.logos img {
	margin: 5px;
	width: 30%;
	vertical-align: baseline;
	height: auto;
}
.logos-row {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
.logos-row img {
	width: 31.5% !important;
	height: auto;
}
.logos-row2 img {
	margin: 5px;
	width: 21.5% !important;
	vertical-align: baseline;
}
video {
	width: 100%    !important;
	height: auto   !important;
}

@media screen and (max-width: 727px) and (orientation: portrait) {
	body {
		font-size: 20px;
		overflow-x: hidden;
	}
	header {
		position: fixed;
		top: 0;
		padding: 10px;
		left: 0;
		right: 0;
		background: rgba(255, 255, 255, 0.76);
		z-index: 4;
		box-sizing: border-box;
	}
	#logo {
		width: 150px;
	}
	.founded {
		position: absolute;
		top: 250px;
		left: 7vw;
		font-size: 4.6vw;
		text-shadow: 2px 2px 12px white;
		z-index: 4;
	}
	.founded hr {
		display: none;
		width: 0em;
		margin-right: 0;
		margin-left: 0;
	}
	footer {
		font-size: 1rem;
		background: white;
		box-shadow: 0 0 17px 7px rgb(255, 255, 255);
	}
	h1 {
		margin-top: 0;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 150%;
	}
	p.welcome {
		text-transform: uppercase;
		font-weight: 300;
		font-size: 1rem;
		margin-bottom: 0;
	}
	.hero {
		position: relative;
		right: 0;
		top: 0;
		width: 100vw;
		height: 105vw;
		background-position: right top;
		background-size: 85% auto;
		background-repeat: no-repeat;
	}
	.grid-container {
		position: relative;
		top: 0;
		left: 7vw;
		width: 86vw;
		padding-bottom: 80px;
	}
	.button-link {
		display: block;
		margin: .3em auto;
		border: 1px solid silver;
		border-radius: 5px;
		padding-right: .3em;
		background: white;
	}
	.button-link a {
		display: block;
		padding: .7em 1.6em .7em .6em;
		/*font-size: 1.3vw;*/
		font-size: 1rem;
		font-weight: 500;
		color: black;
		text-decoration: none;
		background-image: url("../img/plus.svg");
		background-repeat: no-repeat;
		background-position: right;
		background-size: 1.5em;
	}
	.contact-link {
		position: absolute;
		right: 5vw;
		top: 40vw;
	}
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
	body {
		font-size: 20px;
	}

	.grid-container {
		top: 100px;
		padding-bottom: 50px;
	}
	p.welcome {
		font-size: .85rem;
	}
	header {
		position: fixed;
		top: 0;
		left: 0;
		background: rgb(255, 255, 255);
		padding: 20px 17vw 10px 7vw;
		box-shadow: 0 0 10px 10px white;
		z-index: 1;
	}
	#logo {
		width: 150px;
	}
	footer {
		text-align: right;
	}
	footer p {
		margin-right: 2em;
	}
	.button-link {
		display: block;
	}
	.button-link a {
		display: block;
		font-size: 1rem;
	}
}
@media screen and (min-width: 1336px) {
	body {
		font-size: 24px;
	}
	#logo {
		width: 230px;
	}
	h1 {
		font-size: 48px;
	}
	p.welcome {
		font-size: 16px;
	}
	.button-link a {
		font-size: 16px;
	}
/*	.hero {
		width: 700px;
		height: 750px;
	}*/
/*	.founded {
		right: 550px;
		top: 550px;
		font-size: 24px;
	}*/
/*	.contact-link {
		right: 66px;
		top: 345px;
	}*/
	footer {
		font-size: 16px;
	}
}
@media screen and (min-width: 1830px) {
	.hero {
		width: 920px;
		height: 950px;
	}
	.founded {
		right: 740px;
		top: 740px;
		font-size: 28px;
	}
	.contact-link {
		right: 100px;
		top: 465px;
	}
}
.grecaptcha-badge {
	visibility: hidden;
}

.processing:before {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4,end) 900ms infinite;
	animation: ellipsis steps(4,end) 900ms infinite;
	content: "\2026"; /* ascii code for the ellipsis character */
	width: 0px;
	color: #748185;
}
.processing:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4,end) 900ms infinite;
	animation: ellipsis steps(4,end) 900ms infinite;
	content: "\2026"; /* ascii code for the ellipsis character */
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 1.25em;
	}
}


.floating-label {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
}
/*.floating-label label {
	position: absolute;
	top: 1.65em;
	left: 1.1em;
	transition: all .3s ease;
}*/
.floating-label input::placeholder,
.floating-label textarea::placeholder {
	color: #666;
	font-weight: 400;
}
.floating-label input:focus::placeholder,
.floating-label textarea:focus::placeholder {
	color: #0054A6;
}
.floating-label input + label,
.floating-label input ~ label,
.floating-label select + label,
.floating-label textarea + label {
	position: absolute;
	top: 1.1em;
	left: 1.2em;
	transition: all .3s ease;
	opacity: 0;
	font-size: 1rem;
}

.floating-label input:not(:placeholder-shown) + label,
.floating-label input:not(:placeholder-shown) ~ label,
.floating-label textarea:not(:placeholder-shown) + label {
	font-size: 50%;
	transform: translate3d(-5%,-93%,0);
	opacity: 1;
	color: #666;
	background-color: #fff;
	padding: 0 0.5em;
}
.floating-label input:focus + label,
.floating-label input:focus ~ label,
.floating-label textarea:focus + label {
	color: #0054A6;
}
input:-webkit-autofill {
	background-color: transparent !important;
	-webkit-box-shadow: 0 0 0 50px white inset;
}

.project {
	margin: 2rem auto;
	padding: 0 0 2rem 0;
	border-bottom: 1px solid #cccccc;
}
.project h3 {
	margin-bottom: 0;
	font-size: 1.4rem;
}
.project h4 {
	margin-top: 0;
	font-size: 1.2rem;
	font-weight: 400;
}
.project h4 a {
	color: #999999;
	text-decoration: underline;
}
.project p {
	font-size: 70%;
	line-height: 1.5;
}
.project .info {
	margin-block-start: 1em;
	font-size: 66%;
	column-count: 2;
	min-height: 11em;
}
.project#djm-lbx .info {
	height: 17em;
}
.project#fl .info {
	height: 13em;
}
.project#sol .info {
	column-count: 1;
}
.project dl {
	margin-block-start: 0;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}
.project dt {
	font-weight: 600;
}
.project dt::after {
	content: ":";
}
.project dd {
	margin-left: 0;
}
figure {
	margin: 0;
}
.project img {
	width: 100%;
}
.project .double {
	display: flex;
	justify-content: space-between;
}
.project .double img {
	width: calc(50% - 3px);
}